import {
  useContext,
  createContext,
  useEffect,
  useState,
  forwardRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Container,
  Row,
  CardGroup,
  Card,
  Button,
  // Accordion,
  useAccordionToggle,
  AccordionContext,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import UserBubble from '../helpers/userbubble';
import MenuBubble from '../helpers/menububble';
import { MEDIAURL } from '../../utilities/constants';
import { virtiusV } from '../helpers/logos';
import RoundCount from '../session/roundcount';
import * as Icons from '../helpers/icons';
import Header from '../helpers/header';
import { useQueryParams /*useQueryParamsAdv*/ } from '../../utilities/query';
import { chevronUpIcon, chevronDownIcon } from '../helpers/icons';
import {
  SessionSubscriptionManager,
  useExperimental,
  useRotations,
  useLineups,
  useJudgingEnabled,
} from './hooks';
import { useRoutines } from '../../utilities/clips';
import {
  emptyLineup,
  sessionStatus,
  sessionViewType,
} from '../../redux/_constants';
import { producerActions } from '../../redux/_actions';
import TriggerPanel from './triggerpanel';
import CompetitorList from './competitorlist';
import RoundSummary from './roundsummary';
import {
  GYMapparatusAbbv,
  abbvToApparatusName,
  teamBrand,
} from '../../utilities/conversions';
import { isHead2Head } from '../../utilities/session';
import { SessionStatus } from '../../models';
import ToastStack from './toaststack';
import './sessionheader.css';
import './sessionfooter.css';
import './multiexperimental.css';
// import GetLineupWithRoutines from '../../apollo/queries/GetLineupWithRoutines.graphql';
import ScoringPanel, { DisabledScoringPanel } from './scoringpanel';
import EvaluatorSlab from './evaluatorslab';
import { RoutineStatus } from '../../models';
// import { useQuery } from '@apollo/client';
import { Scoreboard } from './scoreboard';
import {
  // TeamBannerCompact,
  TeamBannerLarge,
  TeamBannerCompactBeta,
} from './teambanner';
import { useHotkeys } from 'react-hotkeys-hook';
import VideoPlayer from './videoplayer';
import MatchCountdown from '../helpers/matchcountdown';
import { useUser } from '../../user-provider';
// import { ScoreboardButton } from './scoreboard';

export const SessionContext = createContext(null);

const ToolTip = forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip ref={ref} {...props}>
      {children}
    </Tooltip>
  );
});

function FooterButton({ apparatus, msg }) {
  const content = msg || apparatus;

  const handleClick = () => {};

  return (
    <div className="scoreboard vCenter">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 700, hide: 0 }}
          overlay={<ToolTip id="popover-contained">{content}</ToolTip>}
        >
          <Button
            // id="footerButton"
            variant="light"
            onClick={handleClick}
            className={[
              'vCenter',
              'footerButton',
              msg ? 'activated' : null,
            ].join(' ')}
            disabled
          >
            {Icons?.[`${apparatus}`]}
          </Button>
        </OverlayTrigger>
      </Row>
    </div>
  );
}

function TriggerPanelOnly({ judgeType, judgeLock }) {
  return (
    <Row className="players">
      <Col>
        <TriggerPanel
          row
          judgeType={judgeType}
          judgeLock={judgeLock}
          isExperimental={true}
        />
      </Col>
    </Row>
  );
}

function VideoRow({ clips, streamType, judgeType, judgeLock }) {
  const { session } = useContext(SessionContext);
  const judgingEnabled = useJudgingEnabled();
  const status = useSelector((state) => state.session.status);
  const view = useSelector((state) => state.session.view);
  const { CREATED, PREGAME, LIVE, POSTGAME } = sessionStatus;
  const { MULTIONLY } = sessionViewType;
  const team = useSelector((state) => state.producer['teamA']);
  const isPregame = [CREATED, PREGAME].includes(status);
  const isLive = LIVE === status;
  const isPost = POSTGAME === status;
  const streamCount = session.streams.items.length;
  const isLineupView = view === MULTIONLY;
  const showPlayer =
    (isPregame && !isLineupView) ||
    (isLive && streamCount > 0 && !isLineupView && !judgingEnabled) ||
    (isPost && streamCount > 0 && !isLineupView);

  return showPlayer ? (
    <Row className="players">
      <Col>
        <Row>
          {isPregame ? <MatchCountdown sessionId={session.id} /> : null}
          <VideoPlayer team={team} clips={clips} streamType={streamType} />
        </Row>
        <TriggerPanel
          row
          isExperimental={true}
          judgeType={judgeType}
          judgeLock={judgeLock}
        />
      </Col>
    </Row>
  ) : (
    <TriggerPanelOnly judgeType={judgeType} judgeLock={judgeLock} />
  );
}

function SessionBanner({ isJudge, apparatus }) {
  const { session, logo } = useContext(SessionContext);
  const { name } = session;
  // const apparatusIcon = Icons?.[`${apparatus}noText`];

  return (
    <div className="teamBanner">
      <Row style={{ height: '100%' }}>
        <Col xs={6} className="vCenter logoContainer">
          <Row>
            <Col className="vCenter logo" xs={3}>
              {logo ? (
                <img
                  src={`${MEDIAURL}${logo}`}
                  alt={name ? `${name} logo` : ''}
                />
              ) : (
                virtiusV
              )}
            </Col>
            <Col xs={9} className="vCenter team">
              {name}
            </Col>
          </Row>
        </Col>
        <Col className="vCenter score" xs={6}>
          {/*{apparatusIcon}*/}
          {isJudge ? null : <TeamBannerCompactBeta />}
        </Col>
      </Row>
    </div>
  );
}

function SessionHeader({ isJudge }) {
  return (
    <Container>
      <Row className="headers">
        <Col className="left">
          <SessionBanner isJudge={isJudge} />
        </Col>
        <Col className="soloRoundCount" xs={1}>
          <RoundCount align="LEFT" judgeLock={isJudge} />
        </Col>
      </Row>
    </Container>
  );
}

function Evaluator({ team, sessionId, judgeType }) {
  const judgingEnabled = useJudgingEnabled();
  const {
    // activeLineup,
    session,
    activeApparatus,
    evalRoutine: activeEvalRoutine,
    // setEvalRoutine,
  } = useContext(SessionContext);
  const apparatusesInOrder = GYMapparatusAbbv(session.gender);
  const round =
    apparatusesInOrder.indexOf(
      activeEvalRoutine?.apparatus || activeApparatus
    ) + 1;
  const lineupId = team?.lineupId;
  const routines = useRoutines(lineupId, round);

  // console.log(evalRoutine);
  // console.log(team);
  // console.log({ activeLineup, activeApparatus, round, activeEvalRoutine });
  // console.log('yes');

  // const { data } = useQuery(GetLineupWithRoutines, {
  //   variables: {
  //     id: team?.lineupId || activeLineup?.id,
  //   },
  //   skip: !team?.lineupId && !activeLineup?.id,
  // });

  // Need to filter out duplicates in case and not sorted...
  //const routines = data?.getLineup.routines.items || [];

  const inquiry = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[lineupId]
  );

  const edit = useSelector(
    (state) => state.evaluator.activeEditByLineupId[lineupId]
  );

  const evalRoutine = routines.find(
    (routine) => routine?.status === RoutineStatus.ON_EVAL
  );

  const airRoutine = routines.find(
    (routine) => routine?.status === RoutineStatus.ON_AIR
  );

  // console.log({ routines, lineupId });

  // const activeRoutine = useRoutines()

  const standby =
    !evalRoutine &&
    !airRoutine &&
    routines.find(
      (routine) => !routine || routine.status === RoutineStatus.STANDBY
    );

  // useEffect(() => {
  //   if (activeEvalRoutine?.id !== evalRoutine?.id && round !== 0) {
  //     console.log('firing the useEffect for evalRoutine');
  //     console.log({ activeEvalRoutine, evalRoutine });
  //     setEvalRoutine(evalRoutine);
  //   }
  // }, [evalRoutine, activeEvalRoutine?.id, evalRoutine?.id]);

  if (
    judgingEnabled &&
    (inquiry || edit || evalRoutine) // ||
    //activeEvalRoutine?.status === RoutineStatus.ON_EVAL)
  ) {
    return (
      <ScoringPanel
        team={team}
        sessionId={sessionId}
        routineId={
          inquiry?.routineId ||
          edit?.id ||
          evalRoutine?.id ||
          activeEvalRoutine?.id
        }
        judgeType={judgeType}
      />
    );
  } else {
    return (
      <DisabledScoringPanel
        onAir={airRoutine}
        onEval={evalRoutine}
        onStandby={standby}
        routineId={evalRoutine ? evalRoutine.id : 'FAKE-ROUTINE-ID'}
        judgeType={judgeType}
      />
    );
  }
}

function SessionFooter({ sessionId, judgeType }) {
  const judgingEnabled = useJudgingEnabled();
  const { evalRoutine, activeLineup, activeApparatus } = useContext(
    SessionContext
  );

  const live = useSelector(
    (state) => state.session.status === SessionStatus.LIVE
  );

  const [isVisible, setIsVisible] = useState(live && judgingEnabled);
  const team = {
    lineupId: evalRoutine?.lineupId || activeLineup?.id,
    home: true,
  };

  // console.log({ evalRoutine });

  return (
    <div
      className={['sessionFooter', isVisible ? '' : 'sessionFooterHidden'].join(
        ' '
      )}
    >
      <Container>
        <ToastStack />
        {judgingEnabled ? (
          <Row className="footers">
            <Col className="left" xs={11}>
              <Evaluator
                team={team}
                sessionId={sessionId}
                judgeType={judgeType}
              />
            </Col>
            <Col className="center" xs={1}>
              {judgeType ? null : (
                <Button
                  variant="light"
                  className="footerToggle vCenter"
                  style={{ marginTop: '-6px' }}
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? chevronDownIcon : chevronUpIcon}
                </Button>
              )}
              <FooterButton
                apparatus={activeApparatus || evalRoutine?.apparatus}
                msg={evalRoutine?.athlete?.name || null}
              />
            </Col>
          </Row>
        ) : null}
      </Container>
    </div>
  );
}

function Rotations({
  sessionId,
  fullscreen,
  isAdmin,
  isFan,
  isJudge,
  judgeType,
  apparatusType,
  quad,
  isEvalSlab,
}) {
  const { isFan: isFanUser } = useUser();
  // const isAdmin = cognitoGroups.includes('admin');
  const round = useSelector((state) => state.producer.round);

  const {
    session,
    activeLineup,
    activeApparatus,
    setActiveLineup,
    setActiveApparatus,
    // evalRoutine,
    setEvalRoutine,
  } = useContext(SessionContext);
  const { gender } = session;
  const rotations = useRotations(sessionId);
  //const apparatusesInOrder = getApparatusPossibilitiesByGender(gender);
  const apparatusesInOrder = GYMapparatusAbbv(gender);
  const isLive = useSelector((state) => state.producer.live);
  const isEditing = useSelector((state) => state.producer.edit);
  const lineups = useLineups(sessionId);
  const dispatch = useDispatch();
  const isStacked = !isHead2Head(session);
  const judgingEnabled = useJudgingEnabled();
  const [onlyRotation, setOnlyRotation] = useState(
    judgingEnabled || fullscreen || quad ? 0 : null
  );
  const [minimize, setMinimize] = useState(
    (isFan || isFanUser) && !fullscreen && !quad ? true : false
  );

  useEffect(() => {}, [judgeType]);

  // console.log(activeApparatus);
  // console.log(apparatusesInOrder);
  // console.log(apparatusType);

  useEffect(() => {
    if (
      apparatusType &&
      apparatusesInOrder.includes(apparatusType.toUpperCase())
    ) {
      // console.log('setting apparatus with ', apparatusType);
      setActiveApparatus(apparatusType.toUpperCase());
    }
  }, [apparatusType]);

  useEffect(() => {
    const element = document.getElementById(`rotation_${round}`);

    if ((onlyRotation !== null) & (round !== onlyRotation)) {
      setOnlyRotation(round - 1);
    }

    if (element && window.scrollY !== 0) {
      const headerOffset = 150;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [round, onlyRotation]);

  useEffect(() => {
    if (activeLineup && activeApparatus) {
      setOnlyRotation(round - 1);
    }

    if (!activeLineup && !activeApparatus && onlyRotation) {
      setOnlyRotation(round - 1);
    }

    if (activeLineup === null && activeApparatus) {
      setOnlyRotation(null);
    }
  }, [activeLineup, activeApparatus, round]);

  const handleCardHeader = (e, newRound, lineup, apparatus) => {
    if (round !== newRound) {
      dispatch(producerActions.changeRound(newRound));
      // setEvalRoutine(null);
    }

    if (apparatus !== activeApparatus) {
      setActiveApparatus(apparatus);
      // setEvalRoutine(null);
    }

    if (apparatus === activeApparatus) {
      setActiveApparatus(null);
    }

    if (lineup?.id === activeLineup?.id) {
      setActiveLineup(null);
    }

    if (lineup?.id !== activeLineup?.id) {
      setActiveLineup(lineup);
      setEvalRoutine(null);
    }
  };

  const handleOnlyRotation = (rotation) => {
    if (onlyRotation !== null) {
      setOnlyRotation(null);
    } else {
      setOnlyRotation(rotation);
    }
  };

  const handleMinimize = () => {
    setMinimize(!minimize);
  };

  function ContextAwareToggle({
    children,
    eventKey,
    callback,
    order,
    lineup,
    apparatus,
    isSelected,
  }) {
    const currentEventKey = useContext(AccordionContext);

    const handleClick = () => {
      handleCardHeader(null, order, lineup, apparatus);
      if (isCurrentEventKey) return;
      decoratedOnClick();
    };

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        type="button"
        style={{
          backgroundColor: isSelected ? '#ccc' : '#eee',
          border: 'none',
          padding: '0.5rem',
        }}
        onClick={handleClick}
        className="headerButton"
      >
        {children}
      </button>
    );
  }

  return (
    // <Accordion defaultActiveKey="1" activeKey="1">
    <div className={['rotations', quad ? 'quad' : ''].join(' ')}>
      {Object.values(rotations).map((rotation) => {
        const currentRotation = rotation.order + 1 === round;
        const stagesByApparatus = rotation.stages.items.reduce((acc, stage) => {
          if (!stage._deleted) {
            acc[stage.apparatus] = stage;
          }
          return acc;
        }, {});
        const numPanels = apparatusesInOrder.reduce((acc, a) => {
          let newVal = acc;
          const squadRosters = stagesByApparatus[
            a
          ]?.squad?.rosters?.items.filter((sr) => !sr._deleted);

          if (squadRosters && squadRosters.length > 0) {
            newVal++;
          }
          return newVal;
        }, 0);

        if (
          ((fullscreen || quad) && !currentRotation) ||
          (onlyRotation !== null && rotation.order !== onlyRotation)
        ) {
          return null;
        }

        return (
          <section
            key={rotation.id}
            className="mainRotations"
            id={`rotation_${rotation.order + 1}`}
          >
            <Row>
              <Col className="rotationTitle">
                <h4>Rotation {rotation.order + 1}</h4>
              </Col>
              {/*fullscreen || isFan ? null : (
                <Col className="rotationButtons">
                  <Button variant="light">Active</Button>
                </Col>
              )*/}
              {fullscreen || quad || isJudge ? null : (
                <Col className="rotationButtons">
                  <Button variant="link" onClick={handleMinimize}>
                    {minimize ? 'Lineups ∨' : 'Summary ∧'}
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => handleOnlyRotation(rotation.order)}
                  >
                    {onlyRotation !== null ? (
                      <>
                        Show All <span className="caret">∨</span>
                      </>
                    ) : (
                      <>
                        Show One <span className="caret">∧</span>
                      </>
                    )}
                  </Button>
                </Col>
              )}
            </Row>
            <CardGroup>
              <Row className="rotationRow">
                {apparatusesInOrder.map((apparatus) => {
                  if (
                    apparatus !== activeApparatus &&
                    activeApparatus !== null
                  ) {
                    return null;
                  }

                  const customRound = apparatusesInOrder.indexOf(apparatus) + 1;
                  const squadRosters = stagesByApparatus[
                    apparatus
                  ]?.squad?.rosters?.items.filter((sr) => !sr._deleted);

                  const clTeams =
                    squadRosters?.map((roster, i) => {
                      const lineupId = roster?.lineupId;
                      const lineup = lineups?.[lineupId];

                      const rotationLineup =
                        (lineup?.lineupData &&
                          JSON.parse(lineup?.lineupData)?.[customRound]) ||
                        JSON.parse(
                          JSON.stringify(
                            emptyLineup.slice(0, i > 0 ? 0 : emptyLineup.length)
                          )
                        );

                      const lineupRoster =
                        (roster &&
                          roster.roster.athleteContexts.items
                            .filter((ac) => !ac._deleted)
                            .map((ac) => ac.athleteContext.athlete)
                            .sort((a, b) => {
                              if (a.name.toUpperCase() < b.name.toUpperCase()) {
                                return -1;
                              }
                              if (a.name.toUpperCase() > b.name.toUpperCase()) {
                                return 1;
                              }
                              return 0;
                            })) ||
                        [];

                      return {
                        lineup: rotationLineup,
                        roster: lineupRoster,
                        lineupId,
                        home: true,
                        individuals: lineup?.individuals,
                      };
                    }) || [];

                  return (
                    squadRosters &&
                    squadRosters.map((roster, srIndex) => {
                      const lineupId = roster?.lineupId;

                      if (
                        lineupId !== activeLineup?.id &&
                        activeLineup !== null
                      ) {
                        return null;
                      }

                      // Rejected lower squads
                      if (isStacked && roster.squadStartPosition !== 0) {
                        return null;
                      }

                      const isSelected =
                        apparatus === activeApparatus &&
                        currentRotation &&
                        (isLive || isEditing);

                      const clSlabs = isStacked
                        ? clTeams
                        : clTeams.slice(srIndex, srIndex + 1);

                      return (
                        stagesByApparatus[apparatus] && (
                          <Col
                            xs={quad ? (numPanels > 4 ? 4 : 6) : null}
                            className="rotationRowCol"
                            key={`${rotation.id}_${apparatus}_${roster.id}`}
                          >
                            {clSlabs.map((cl, i) => {
                              const teamBranding =
                                cl &&
                                cl?.lineupId &&
                                teamBrand(lineups[cl.lineupId].team);
                              const offset = clTeams
                                .slice(0, i)
                                .reduce((acc, o) => acc + o.lineup.length, 0);
                              const hideCardGap =
                                cl?.lineup?.length === 0 && !isEditing
                                  ? 'hideCardGap'
                                  : '';
                              return (
                                <Card
                                  key={`${rotation.id}_${apparatus}_${roster.id}_${cl.lineupId}`}
                                  className={hideCardGap}
                                >
                                  {(i === 0 || isEditing) && (
                                    <ContextAwareToggle
                                      eventKey={rotation.id}
                                      order={rotation.order + 1}
                                      lineup={{ id: cl.lineupId }}
                                      apparatus={apparatus}
                                      isSelected={isSelected}
                                    >
                                      <Row>
                                        <Col
                                          className="cardHeaderApparatus"
                                          xs={6}
                                        >
                                          {Icons?.[`${apparatus}noText`]}
                                          <span>
                                            {abbvToApparatusName[apparatus]}
                                          </span>
                                        </Col>
                                        <Col className="cardHeaderTeam" xs={6}>
                                          <span>{teamBranding?.name}</span>
                                          <img
                                            className="scoreboardHeaderLogo"
                                            src={
                                              cl &&
                                              `${MEDIAURL}${teamBranding?.logo}`
                                            }
                                            alt=""
                                          />
                                        </Col>
                                      </Row>
                                    </ContextAwareToggle>
                                  )}
                                  <Card.Body
                                    className={[
                                      minimize ? 'minimize' : null,
                                      isEvalSlab && activeLineup
                                        ? 'evalSlabCard'
                                        : null,
                                    ].join(' ')}
                                  >
                                    <CompetitorList
                                      team={cl}
                                      left={true}
                                      customRound={customRound}
                                      apparatus={apparatus}
                                      showHeadshot={isSelected}
                                      judgeLock={isJudge}
                                      offset={offset}
                                      defaultLength={cl.lineup.length}
                                      isEvalSlab={isEvalSlab && activeLineup}
                                    />
                                    {isEvalSlab && activeLineup && i === 0 ? (
                                      // TODO: Need to figure out a way to activate scoring panel for multi-lineups
                                      <EvaluatorSlab
                                        round={customRound}
                                        team={cl}
                                        sessionId={sessionId}
                                        judgeType={judgeType}
                                      />
                                    ) : null}
                                  </Card.Body>
                                  {!isEditing && !cl.individuals && (
                                    <Card.Footer>
                                      {stagesByApparatus[apparatus] &&
                                        !isJudge && (
                                          <RoundSummary
                                            team={clTeams[srIndex]}
                                            left={true}
                                            round={
                                              apparatusesInOrder.indexOf(
                                                apparatus
                                              ) + 1
                                            }
                                            isExperimental={true}
                                          />
                                        )}
                                    </Card.Footer>
                                  )}
                                </Card>
                              );
                            })}
                          </Col>
                        )
                      );
                    })
                  );
                })}
              </Row>
            </CardGroup>
          </section>
        );
      })}
    </div>
    // </Accordion>
  );
}

function MultiExperimental({ sessionId }) {
  const [showLB, setShowLB] = useState(false);
  const experimental = useExperimental({ sessionId });
  const queryParams = useQueryParams();
  const queryBeta = queryParams.get('beta');
  const isFullScreen = queryBeta === 'fullscreen';
  const isQuad = queryBeta === 'quad';
  const isAdmin = queryBeta === 'admin';
  const isJudge = queryBeta === 'judge' || queryParams.has('judge');
  const isEvalSlab = queryParams.has('judge') && queryParams.has('evalslab');
  const isFan = queryBeta === 'fan';
  const queryTheme = queryParams.has('theme');
  const queryJudgeType = queryParams.get('judge');
  const queryApparatusType = queryParams.get('apparatus');
  const queryStreamType = queryParams.get('stream');
  const isTransparent =
    queryTheme &&
    ['2', 'transparent'].includes(
      String(queryParams.get('theme')).toLowerCase()
    );
  const isDarkMode =
    queryTheme &&
    ['1', 'dark'].includes(String(queryParams.get('theme')).toLowerCase());

  useHotkeys(
    'l',
    (e) => {
      e.preventDefault();
      setShowLB(!showLB);
    },
    [showLB]
  );

  useEffect(() => {
    if (isTransparent) {
      document.documentElement.setAttribute('data-theme', 'transparent');
    } else if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', null);
    }
  }, [isTransparent, isDarkMode]);

  return (
    <SessionContext.Provider value={experimental}>
      <div className="main session experimental">
        <Header className={isQuad ? 'quadHeader' : null}>
          {isFullScreen || isQuad ? null : <MenuBubble />}
          <SessionHeader isJudge={isJudge} />
          {isFullScreen || isQuad ? null : <UserBubble />}
        </Header>
        <Container className="soloPlayer">
          {isFullScreen || isQuad ? null : (
            <VideoRow
              streamType={queryStreamType}
              judgeType={queryJudgeType}
              judgeLock={isJudge}
            />
          )}
          <Rotations
            sessionId={sessionId}
            fullscreen={isFullScreen}
            isFan={isFan}
            isJudge={isJudge}
            isAdmin={isAdmin}
            judgeType={queryJudgeType}
            apparatusType={queryApparatusType}
            quad={isQuad}
            isEvalSlab={isEvalSlab}
          />
        </Container>
        {isFullScreen || isQuad ? (
          <Container className="soloPlayer">
            <TeamBannerLarge />
          </Container>
        ) : null}
      </div>

      {isFullScreen || isQuad || isFan ? null : (
        <SessionFooter sessionId={sessionId} judgeType={queryJudgeType} />
      )}
      <Scoreboard sessionId={sessionId} isLeaderBoard={showLB} />
      <SessionSubscriptionManager sessionId={sessionId} />
    </SessionContext.Provider>
  );
}

export default MultiExperimental;
