/*global Rollbar */
import { SessionHostType } from '../models';
import { teamScoreType, judgePanelType } from '../redux/_constants';
import {
  RoutineStatus,
  ScoreType,
  JudgePanelType,
  PenaltyType,
  PenaltyStatus,
} from '../models';
import { useEvalConfig, useDisplayConfig } from '../components/session/hooks';
import {
  displayName,
  GYMapparatus,
  GYMapparatusAbbv,
} from '../utilities/conversions';
import { dualTeamBRotation } from '../utilities/session';

export const scoreEHandler = ({
  val = '',
  inputKey = '',
  inputString = '',
}) => {
  let targetInput = '';
  let targetKey = inputKey || '';
  let targetInternalScore = '';

  // console.log('value: ', val);
  // console.log('input string: ', inputString)
  // console.log('input key: ', inputKey)

  // Edge cases
  // Scroll up / down to ends
  // Copy and paste random
  // Type input without .
  // Extra same character input
  // Delete doesn't change number
  // Start with period, need to auto add 0
  // Cancel out 'e' & '-' character
  // Scroll down to 0 from nothing yields 0.0
  // Delete 1 from 0.1 yields 0, track key & input

  // Inputs:
  // 10, 100, 10.0
  // Copy paste: 0, 0.0, 234
  // Arrows -> 0, -> 10
  // Delete digits of 1.0

  if (val === '') {
    targetInput = '';
  } else if (val === '1.00' || (val === '10' && inputString === '9.9')) {
    targetInput = '100';
  } else if (inputKey === '' && inputString === '' && val === '0') {
    targetInput = val + '0';
  } else if (
    '0123456789'.includes(val) &&
    Math.abs((Number(val) - Number(inputString)).toFixed(1)) === 0.1
  ) {
    targetInput = val + '0';
  } else if (scoreCheckStrict(val) !== scoreCheckStrict(inputString)) {
    targetInput = val;
  } else {
    targetInput = inputString;
  }

  // Side effects

  // Reset inputKey if value deleted
  if (val === '') {
    targetKey = '';
  }

  //
  // setInputString(targetInput);
  const formattedScore = scoreCheckStrict(targetInput);

  // console.log('targetInput: ', targetInput)
  // console.log('scoreCheck: ', formattedScore)
  // console.log('Number(scoreCheck): ', Number(formattedScore));

  if (formattedScore.slice(-2) === '.0' || val[0] === '.') {
    targetInternalScore = Number(formattedScore).toFixed(1);
  } else if (formattedScore.slice(-2, -1) === '.') {
    targetInternalScore = Number(formattedScore);
  } else {
    targetInternalScore = val;
  }

  return {
    internalScore: targetInternalScore,
    inputString: targetInput,
    inputKey: targetKey,
  };
};

// Allows for lazy input of single numeric characters, i.e. 5 -> 5.0
export const scoreCheck = (score) => {
  const re = /[0123456789.]+/g;
  let cleanScore = '';
  let points = '';
  let tenths = '';

  if (score === null) {
    return score;
  }

  // remove non-allowed characters
  cleanScore = (score.match(re) || []).join('');

  // handle empty entry
  if (cleanScore.length === 0) {
    return '';
  }

  // check perfect 10
  if (
    cleanScore.substring(0, 4) === '10.0' ||
    cleanScore.substring(0, 3) === '100' ||
    cleanScore.substring(0, 3) === '10.'
  ) {
    return '10.0';
  }

  // start from first char
  if (cleanScore[0] === '.') {
    points = '0';
  } else {
    points = cleanScore[0];
    if (cleanScore.length === 1) {
      tenths = '0';
    }
  }

  // start with second char
  if (cleanScore.length > 1 && cleanScore[1] !== '.') {
    tenths = cleanScore[1];
  } else if (cleanScore.length > 2 && cleanScore[2] !== '.') {
    tenths = cleanScore[2];
  }

  return points + '.' + tenths;
};

// Requires full consistent input of numeric characters, i.e. 5 -> 5. not 5.0
export const scoreCheckStrict = (score) => {
  const re = /[0123456789.]+/g;
  let cleanScore = '';
  let points = '';
  let tenths = '';

  if (score === null) {
    return score;
  }

  // remove non-allowed characters
  cleanScore = (score.match(re) || []).join('');

  // handle empty entry
  if (cleanScore.length === 0) {
    return '';
  }

  // check perfect 10
  if (
    cleanScore.substring(0, 4) === '10.0' ||
    cleanScore.substring(0, 3) === '100' ||
    cleanScore.substring(0, 3) === '10.'
  ) {
    return '10.0';
  }

  // start from first char
  if (cleanScore[0] === '.') {
    points = '0';
  } else {
    points = cleanScore[0];
  }

  // start with second char
  if (cleanScore.length > 1 && cleanScore[1] !== '.') {
    tenths = cleanScore[1];
  } else if (cleanScore.length > 2 && cleanScore[2] !== '.') {
    tenths = cleanScore[2];
  }

  return points + '.' + tenths;
};

// Requires full consistent input of numeric characters, i.e. 5 -> 5. not 5.0
// For J score input format with 2 decimal places where last digit has to be 0 / 5
export const scoreJCheckStrict = (score) => {
  const re = /[0123456789.]+/g;
  let cleanScore = '';
  let points = '';
  let tenths = '';
  let hundredths = '';

  if (score === null) {
    return score;
  }

  // remove non-allowed characters
  cleanScore = (score.match(re) || []).join('');

  // console.log({score, cleanScore})

  // handle empty entry
  if (cleanScore.length === 0) {
    return '';
  }

  // check perfect 10
  if (
    cleanScore === '1000' ||
    cleanScore === '10000' ||
    cleanScore === '10.00' ||
    cleanScore === '10.000'
  ) {
    return '10.00';
  }

  // check perfect 10
  if (
    // cleanScore.substring(0, 5) === '10.00' ||
    cleanScore.substring(0, 4) === '10.0' ||
    cleanScore.substring(0, 3) === '100' ||
    cleanScore.substring(0, 3) === '10.' // ||
    //cleanScore.substring(0, 2) === '10'
  ) {
    return '10.0';
  }

  // start from first char
  if (cleanScore[0] === '.') {
    points = '0';
  } else {
    points = cleanScore[0];
  }

  // start with second char
  if (cleanScore.length > 1 && cleanScore[1] !== '.') {
    tenths = cleanScore[1];
  } else if (cleanScore.length > 2 && cleanScore[2] !== '.') {
    tenths = cleanScore[2];
  }

  // start with third char and must be 0 or 5
  if (cleanScore.length > 2) {
    if (cleanScore[0] === '.') {
      if (['0', '5'].includes(cleanScore[2])) {
        hundredths = cleanScore[2];
      }
    } else if (cleanScore[1] !== '.' && cleanScore[2] !== '.') {
      if (['0', '5'].includes(cleanScore[2])) {
        hundredths = cleanScore[2];
      }
    } else if (cleanScore.length > 3) {
      if (cleanScore[1] === '.' || cleanScore[2] === '.') {
        if (['0', '5'].includes(cleanScore[3])) {
          hundredths = cleanScore[3];
        }
      }
    }
  }

  return points + '.' + tenths + hundredths;
};

// Requires full input of significant digits i.e. 5 is not good, need 5.0
export const scoreValidStrict = (score) => {
  if (
    score === null ||
    (typeof score !== 'number' && typeof score !== 'string')
  ) {
    return false;
  }

  // Score comes in as String
  const numScore = Number(score);
  const strScore =
    typeof score === 'number' ? score.toFixed(1) : score.toString();
  return numScore >= 0 && numScore <= 10.0 && strScore.length > 2;
};

// Lazy input of single digit numbers, i.e. 5 is === 5.0
export const scoreValid = (score) => {
  if (
    score === null ||
    (typeof score !== 'number' && typeof score !== 'string')
  ) {
    return false;
  }

  // Score comes in as String
  const numScore = Number(score);
  const strScore =
    typeof score === 'number' ? score.toFixed(1) : score.toString();
  return numScore >= 0 && numScore <= 10.0 && strScore.length >= 1;
};

export function sessionScore(routines, teamScoringType) {
  let score = null;
  if (routines) {
    score = routines
      .reduce((routinesByRound, routine) => {
        if (!routinesByRound[routine.rotation]) {
          routinesByRound[routine.rotation] = [];
        }
        if (routine.status === RoutineStatus.COMPLETE) {
          routinesByRound[routine.rotation].push(routine);
        }
        return routinesByRound;
      }, [])
      .reduce(
        (total, roundRoutines) =>
          total + roundScore(roundRoutines, teamScoringType),
        0
      );
  }
  return score;
}

export function sessionPenalties(lineup) {
  let neutral = 0;
  let conduct = 0;

  if (lineup) {
    const teamPenaltyDeduction = lineup.penalties.items.find(
      (penalty) =>
        penalty.status === PenaltyStatus.ISSUED &&
        penalty.type === PenaltyType.NEUTRAL
    );

    neutral = teamPenaltyDeduction?.value || 0;

    const teamPenaltyConduct = lineup.penalties.items.find(
      (penalty) =>
        penalty.status === PenaltyStatus.ISSUED &&
        penalty.type === PenaltyType.YELLOWCARD
    );

    conduct = teamPenaltyConduct?.value || 0;
  }

  return { neutral, conduct };
}

// Final score calculator function for team score, if round is null will calculate all rounds
export function sessionScoreLegacy(lineup, teamScoring) {
  // For postgame calculations
  if (!lineup) {
    return 0;
  }

  const score = Object.keys(lineup).reduce((total, round) => {
    return total + roundScoreLegacy(lineup[round], teamScoring);
  }, 0);

  return Math.trunc(1000 * score);
}

function filterRoutines(routines, teamScoring) {
  const {
    TOP5OF6,
    TOP6,
    TOP6HL,
    TOP5,
    TOP4,
    TOP3,
    BEST5,
    BEST4,
    BEST3,
    ALL,
    NONE,
  } = teamScoreType;

  const completedRoutines = routines.reduce((acc, routine) => {
    if (routine.status === RoutineStatus.COMPLETE) {
      acc.push(routine);
    }

    return acc;
  }, []);

  switch (teamScoring) {
    case TOP5OF6:
      return completedRoutines
        .filter((routine) => routine.order < 6)
        .sort((a, b) => b.score - a.score)
        .slice(0, 5);
    case TOP6HL:
      return completedRoutines
        .filter((routine) => routine.order < 6)
        .sort((a, b) => b.score - a.score)
        .slice(1, 5);
    case TOP6:
      return completedRoutines.filter((routine) => routine.order < 6);
    case TOP5:
      return completedRoutines.filter((routine) => routine.order < 5);
    case TOP4:
      return completedRoutines.filter((routine) => routine.order < 4);
    case TOP3:
      return completedRoutines.filter((routine) => routine.order < 3);
    case BEST5:
      return completedRoutines.sort((a, b) => b.score - a.score).slice(0, 5);
    case BEST4:
      return completedRoutines.sort((a, b) => b.score - a.score).slice(0, 4);
    case BEST3:
      return completedRoutines.sort((a, b) => b.score - a.score).slice(0, 3);
    case NONE:
      // TODO: maybe use this feature for All Around style? Now just return 0
      return [];
    case ALL:
    default:
  }
  return completedRoutines;
}

// Team round score calculator for single round single size (based on team score type)
export const roundScore = (routines, teamScoring = 'ALL') => {
  if (!routines) {
    return 0;
  }

  const filteredRoutines = filterRoutines(routines, teamScoring);

  return filteredRoutines.reduce((total, athlete) => {
    return total + athlete.score;
  }, 0);
};

export const aaCriteria = (type, gender) => {
  let maxOrder = -1;
  const maxApparatus = Object.keys(GYMapparatus(gender)).length;

  switch (type) {
    case teamScoreType.TOP5OF6:
      maxOrder = 6;
      break;
    case teamScoreType.TOP5:
      maxOrder = 7; // Special Case for NCAAM with 2x AAs
      break;
    case teamScoreType.TOP6:
    case teamScoreType.TOP6HL:
    case teamScoreType.BEST5:
      maxOrder = 6;
      break;
    case teamScoreType.TOP4:
    case teamScoreType.BEST4:
      maxOrder = 4;
      break;
    case teamScoreType.TOP3:
    case teamScoreType.BEST3:
      maxOrder = 3;
      break;
    case teamScoreType.ALL:
    case teamScoreType.NONE:
    default:
      maxOrder = -1;
      break;
  }

  return {
    maxApparatus,
    maxOrder,
  };
};

export const prettyHostType = (type) => {
  switch (type) {
    case SessionHostType.USER:
      return 'User';
    case SessionHostType.TEAM:
      return 'Team';
    case SessionHostType.NONE:
    default:
      return 'None';
  }
};

export const prettyTeamScoring = (type) => {
  switch (type) {
    case teamScoreType.TOP5OF6:
      return 'Top 5 of 6';
    case teamScoreType.TOP6HL:
      return 'Top 6 - Drop Hi/Lo';
    case teamScoreType.TOP6:
      return 'Top 6';
    case teamScoreType.TOP5:
      return 'Top 5';
    case teamScoreType.TOP4:
      return 'Top 4';
    case teamScoreType.TOP3:
      return 'Top 3';
    case teamScoreType.BEST5:
      return 'Best 5';
    case teamScoreType.BEST4:
      return 'Best 4';
    case teamScoreType.BEST3:
      return 'Best 3';
    case teamScoreType.ALL:
      return 'All';
    case teamScoreType.NONE:
      return 'None';
    default:
      return '';
  }
};

export const prettyJudgePanel = (type) => {
  switch (type) {
    case judgePanelType.NCAAM:
      return 'NCAA Mens 1D/2E';
    case judgePanelType.NCAAM4:
      return 'NCAA Mens 1D/4E';
    case judgePanelType.NCAAW:
      return 'NCAA Womens SV/2J';
    case judgePanelType.NCAAW2:
      return 'NCAA Womens 2SV/2J';
    case judgePanelType.NCAAW2SV:
      return 'NCAA Womens 2SV/2J (Strict)';
    case judgePanelType.NCAAW4:
      return 'NCAA Womens 4SV/4J (Strict)';
    case judgePanelType.NCAAW6:
      return 'NCAA Womens 6SV/6J (Strict)';
    case judgePanelType.FIG4:
      return 'FIG 2D/4E';
    case judgePanelType.FIG5:
      return 'FIG 2D/5E';
    case judgePanelType.FIG6:
      return 'FIG 2D/6E';
    case judgePanelType.FAN:
      return 'Fan';
    case judgePanelType.SINGLE:
      return 'Single 1D/1E';
    case judgePanelType.J4:
      return 'Standard 4J';
    case judgePanelType.OPEN:
      return 'Open Scoring';
    default:
      return '';
  }
};

// For legacy sessions that have scores in their lineups...
export const roundScoreLegacy = (lineup, teamScoring = 'ALL') => {
  const { TOP5OF6, TOP6HL, TOP6, TOP5, TOP4, TOP3, ALL, NONE } = teamScoreType;
  let score = 0;
  let filteredLineup = JSON.parse(JSON.stringify(lineup));

  if (!lineup) {
    return score;
  }

  switch (teamScoring) {
    case TOP5OF6:
      // need to drop extra scores, then sort then take the top 5
      filteredLineup = filteredLineup
        .slice(0, 6)
        .sort((a, b) => b.score - a.score)
        .slice(0, 5);
      break;
    case TOP6HL:
      filteredLineup = filteredLineup
        .slice(0, 6)
        .sort((a, b) => b.score - a.score)
        .slice(1, 5);
      break;
    case TOP6:
      filteredLineup = filteredLineup.slice(0, 6);
      break;
    case TOP5:
      filteredLineup = filteredLineup.slice(0, 5);
      break;
    case TOP4:
      filteredLineup = filteredLineup.slice(0, 4);
      break;
    case TOP3:
      filteredLineup = filteredLineup.slice(0, 3);
      break;
    case NONE:
      // TODO: maybe use this feature for All Around style? Now just return 0
      filteredLineup = [];
      break;
    case ALL:
    default:
      break;
  }

  score = filteredLineup.reduce((total, athlete) => {
    return total + Number(athlete.score);
  }, 0);

  return score;
};

export const newestScoreOfType = (scores, type) =>
  scores
    .filter((score) => score.type === type)
    .reduce((newest, score) => {
      if (!newest) {
        return score;
      }

      const timeStamp1 = score.createdAt ?? score.time;
      const timeStamp2 = newest.createdAt ?? newest.time;

      return new Date(timeStamp1) > new Date(timeStamp2) ? score : newest;
    }, null);

export const convertMillipointsToPoints = (scoreMp) => scoreMp / 1000;

export const convertPointsToMillipoints = (score) => Math.round(score * 1000);

export const convertMillipointsToDisplay = (scoreMp, decimalPlaces = 3) =>
  (scoreMp / 1000).toFixed(decimalPlaces);

export const convertDisplayToMillipoints = (score, decimalPlaces = 3) => {
  let result = parseFloat(score) * 1000;

  if (decimalPlaces <= 3) {
    result = Math.round(result);
  }

  return result;
};

export const TEN = 10000;

export const scoreTypeToDisplay = (type) => {
  switch (type) {
    case ScoreType.E:
    case ScoreType.E1:
    case ScoreType.E2:
    case ScoreType.E3:
    case ScoreType.E4:
    case ScoreType.E5:
    case ScoreType.E6:
      return 'Execution Deduction';
    case ScoreType.F:
      return 'Fan Score';
    case ScoreType.OPEN:
      return 'Open Score';
    case ScoreType.J:
      return 'Judge';
    case ScoreType.J1:
      return 'Judge 1';
    case ScoreType.J2:
      return 'Judge 2';
    case ScoreType.J3:
      return 'Judge 3';
    case ScoreType.J4:
      return 'Judge 4';
    case ScoreType.J5:
      return 'Judge 5';
    case ScoreType.J6:
      return 'Judge 6';
    case ScoreType.D:
    case ScoreType.D1:
    case ScoreType.D2:
      return 'Difficulty';
    case ScoreType.SV:
      return 'Start Value';
    case ScoreType.SV1:
      return 'Start Value 1';
    case ScoreType.SV2:
      return 'Start Value 2';
    case ScoreType.SV3:
      return 'Start Value 3';
    case ScoreType.SV4:
      return 'Start Value 4';
    case ScoreType.SV5:
      return 'Start Value 5';
    case ScoreType.SV6:
      return 'Start Value 6';
    case ScoreType.ND:
      return 'Neutral Deduction';
    case ScoreType.SB:
      return 'Stick Bonus';
    default:
      return 'Unknown score type';
  }
};

export const scorePrecisionToDisplay = (type, config) => {
  const {
    svPrecision = 2,
    dPrecision = 1,
    ePrecision = 1,
    sbPrecision = 1,
    jPrecision = 2,
    nPrecision = 1,
    scorePrecision = 3,
  } = config;

  switch (type) {
    case ScoreType.E:
    case ScoreType.E1:
    case ScoreType.E2:
    case ScoreType.E3:
    case ScoreType.E4:
    case ScoreType.E5:
    case ScoreType.E6:
      return ePrecision;
    case ScoreType.F:
      return 0;
    case ScoreType.J:
    case ScoreType.J1:
    case ScoreType.J2:
    case ScoreType.J3:
    case ScoreType.J4:
    case ScoreType.J5:
    case ScoreType.J6:
      return jPrecision;
    case ScoreType.D:
    case ScoreType.D1:
    case ScoreType.D2:
      return dPrecision;
    case ScoreType.SV:
    case ScoreType.SV1:
    case ScoreType.SV2:
    case ScoreType.SV3:
    case ScoreType.SV4:
    case ScoreType.SV5:
    case ScoreType.SV6:
      return svPrecision;
    case ScoreType.ND:
      return nPrecision;
    case ScoreType.SB:
      return sbPrecision;
    case ScoreType.OPEN:
    default:
      return scorePrecision;
  }
};

// Scores must be in a dictionary form of "E1": Score
export const scoreEAverage = (config, scores) => {
  const execution = [];
  const scoresObj = {};
  const len = config.ePanel.length;
  const toplineScoreType = config?.dPanel[0]?.type;
  const toplineScore = newestScoreOfType(scores, toplineScoreType)?.value;
  const noTruncation = config.startValue && len === 6; // special case for NCAAW6 x.xxxx

  for (let e = 0; e < len; e++) {
    execution.push(newestScoreOfType(scores, config.ePanel[e].type)?.value);
    scoresObj[config.ePanel[e].type] =
      (config.startValue ? toplineScore : TEN) - execution[e];
  }

  const result =
    len < 4
      ? Object.values(scoresObj).reduce((a, b) => a + b, 0) / len
      : (Object.values(scoresObj).reduce((a, b) => a + b, 0) -
          Math.max(...Object.values(scoresObj)) -
          Math.min(...Object.values(scoresObj))) /
        (len - 2);

  return noTruncation ? result : Math.trunc(result);
};

// Scores must be in a dictionary form of "J1": Score
export const scoreJAverage = (config, scores) => {
  const judging = [];
  const len = config.jPanel.length;

  for (let j = 0; j < len; j++) {
    judging.push(newestScoreOfType(scores, config.jPanel[j].type)?.value);
  }

  const scoreJFiltered = judging.filter((e) => e !== undefined);
  const result =
    scoreJFiltered.reduce((a, b) => a + b, 0) / scoreJFiltered.length;

  return result;
};

// Averages scores with no truncation
export const scoreAvgNoTrunc = (scores) => {
  if (!scores) {
    return 0;
  }

  const result =
    scores && scores.length < 4
      ? scores.reduce((a, b) => a + b, 0) / scores.length
      : (scores.reduce((a, b) => a + b, 0) -
          Math.max(...scores) -
          Math.min(...scores)) /
        (scores.length - 2);

  return result || 0;
};

// Scoring hook to provide all details of the routine, used for leaderboard and scorecard

export function useRoutineScore() {
  const evalConfig = useEvalConfig();

  function score(routine) {
    if (!routine) {
      return {};
    }

    const score =
      !routine?.score && routine?.score !== 0 ? null : routine.score;
    const updatedAt = routine?.updatedAt || Date.now();
    const isEvaluatorFinalize = !routine?.updatedAt;
    const nowBuffer = 1000;

    const scores =
      (!!routine?.scores?.items &&
        routine?.scores?.items?.filter((score) => {
          const createdAt = new Date(score.createdAt).getTime();
          const now = new Date(updatedAt).getTime();
          const buffer = isEvaluatorFinalize ? nowBuffer : 0;

          // if (isEvaluatorFinalize) {
          //   console.log(createdAt - now);
          // }

          if (isEvaluatorFinalize && createdAt > now) {
            console.log(
              `Detected score component ${score.type} ${score.id} to be > now.`,
              { updatedAt, score, delta: createdAt - now }
            );
            Rollbar.error(
              `Detected score component ${score.type} ${score.id} to be > now.`,
              { updatedAt, score, delta: createdAt - now }
            );
          }

          return score.id !== 'FAKE-SCORE-ID' && createdAt <= now + buffer;
        })) ||
      [];

    //console.log({ scores });
    const isJudging = evalConfig?.jPanel?.length > 0;
    const isStartValue = !!evalConfig?.startValue;

    const toplineScoreType = evalConfig?.dPanel[0]?.type;
    const newestToplineScore = newestScoreOfType(scores, toplineScoreType);
    const toplineScore = newestToplineScore ? newestToplineScore.value : null;
    const noTruncation =
      isStartValue &&
      (evalConfig.ePanel.length === 6 || evalConfig.jPanel.length === 6); // special case for NCAAW6 x.xxxx

    const difficulty = [];
    const execution = [];
    const judging = [];
    const startValue = [];
    const scoresObj = {};

    for (let d = 0; d < evalConfig.dPanel.length; d++) {
      difficulty.push(
        newestScoreOfType(scores, evalConfig.dPanel[d].type)?.value
      );
    }

    for (let e = 0; e < evalConfig.ePanel.length; e++) {
      execution.push(
        newestScoreOfType(scores, evalConfig.ePanel[e].type)?.value
      );
      scoresObj[evalConfig.ePanel[e].type] =
        (isStartValue ? toplineScore : TEN) - execution[e];
    }

    for (let j = 0; j < evalConfig?.jPanel?.length; j++) {
      judging.push(
        newestScoreOfType(scores, evalConfig?.jPanel[j]?.type)?.value
      );
    }

    for (let sv = 0; sv < evalConfig?.svPanel?.length; sv++) {
      startValue.push(
        newestScoreOfType(scores, evalConfig?.svPanel[sv]?.type)?.value
      );
    }

    const scoreND = newestScoreOfType(scores, 'ND')?.value || 0;
    const scoreSB = newestScoreOfType(scores, 'SB')?.value || 0;

    const scoreJFiltered = judging.filter((e) => e !== undefined);
    const scoreDFiltered = difficulty.filter((e) => e !== undefined);
    const scoreEFiltered = execution.filter((e) => e !== undefined);
    const scoreSVFiltered = startValue.filter((e) => e !== undefined);

    const scoreJDelta =
      scoreJFiltered.length > 0
        ? Math.abs(Math.max(...scoreJFiltered) - Math.min(...scoreJFiltered))
        : null;

    const scoreJAvgNoTrunc = scoreAvgNoTrunc(scoreJFiltered);
    const scoreJAverage = noTruncation
      ? scoreJAvgNoTrunc
      : Math.trunc(scoreJAvgNoTrunc);

    const scoreEDelta =
      scoreEFiltered.length > 0
        ? Math.abs(Math.max(...scoreEFiltered) - Math.min(...scoreEFiltered))
        : null;

    const scoreEAvgNoTrunc = scoreAvgNoTrunc(Object.values(scoresObj));
    const scoreEAverage = noTruncation
      ? scoreEAvgNoTrunc
      : Math.trunc(scoreEAvgNoTrunc);

    const jScoresComplete =
      scoreJFiltered.length === evalConfig?.jPanel?.length;
    const subScoresComplete = isJudging
      ? jScoresComplete
      : toplineScore !== null &&
        scoreEFiltered.length === evalConfig.ePanel.length;

    const scoreTotalNoTrunc =
      (isStartValue || isJudging ? 0 : toplineScore) +
      scoreJAverage +
      scoreEAverage -
      scoreND +
      scoreSB;

    const scoreTotal = noTruncation
      ? scoreTotalNoTrunc
      : Math.trunc(scoreTotalNoTrunc);

    const exhibition =
      !!evalConfig?.exhibitionIndex &&
      routine.order >= evalConfig?.exhibitionIndex - 1;

    return {
      score,
      scoreD: toplineScore,
      scoreSB,
      scoreEAverage,
      scoreEDelta,
      scoreND,
      scoreDType: toplineScoreType,
      scoreJAverage,
      scoreJDelta,
      scoresJ: scoreJFiltered,
      scoresE: scoreEFiltered,
      scoresD: scoreDFiltered,
      scoresSV: scoreSVFiltered,
      scoreTotal,
      scoresObj,
      subScoresComplete,
      exhibition,
    };
  }

  return score;
}

export function useRotationScore() {
  function score(routines, apparatus, teamScoringType) {
    if (!apparatus) {
      return null;
    }

    const eventRoutines = routines.filter((r) => r.apparatus === apparatus);
    const filteredTeamRoutines = filterRoutines(eventRoutines, teamScoringType);
    const rotationScore = roundScore(eventRoutines, teamScoringType);
    const averageScore = !!filteredTeamRoutines.length
      ? rotationScore / filteredTeamRoutines.length
      : null;

    return {
      score: rotationScore,
      average: averageScore,
      routines: eventRoutines,
      teamRoutines: filteredTeamRoutines,
      teamRoutineIds: filteredTeamRoutines.map((r) => r.id),
    };
  }

  return score;
}

export function useTeamScore() {
  function score(team) {
    return 0;
  }

  return score;
}

export function useTeamsData() {
  const routineScore = useRoutineScore();
  const displayConfig = useDisplayConfig();
  const teamRotations = useTeamRotations();
  const rotationScore = useRotationScore();

  const {
    svPrecision,
    dPrecision,
    ePrecision,
    avgPrecision,
    scorePrecision,
    nPrecision,
    jPrecision,
    sbPrecision,
  } = displayConfig;
  const dScorePrecision = svPrecision || dPrecision;
  const allScores = [];
  const rankings = {
    team: [],
    AA: {},
  };
  const teams = [];
  const errors = [];

  function teamData(
    teamLineups,
    teamScoringType,
    genderType,
    isAlternating,
    rotations
  ) {
    const lineups = [...teamLineups]
      .slice()
      .filter((l) => !l?._deleted)
      .sort((a, b) => a.order - b.order);
    const apparatusSet = GYMapparatus(genderType);

    for (let i = 0; i < lineups.length; i++) {
      const lineupData = JSON.parse(lineups[i].lineupData);
      const currentLineup = lineups[i];
      const { title, forceTitle, individuals } = currentLineup;
      const isDualAlternating =
        teamLineups.length === 2 && isAlternating && currentLineup?.order === 1;
      let rotationOrder = teamRotations(
        rotations,
        currentLineup?.teamId,
        currentLineup?.id
      );

      // Handle Sessions without Rotation Configurations
      if (!rotationOrder.configured) {
        rotationOrder = legacyTeamRotations(
          GYMapparatusAbbv(genderType),
          isDualAlternating
        );
      }

      // Filtering duplicates of apparatus/order and deleted
      const routines = Object.values(
        currentLineup?.routines.items.reduce((acc, item) => {
          const key = `${item?.athlete?.name}, ${item?.lineup?.team?.name}, ${
            item.apparatus
          }, ${item.order + 1}`; // Create a composite key
          if (item.status !== RoutineStatus.DELETED) {
            if (acc[key]) {
              if (new Date(acc[key].updatedAt) < new Date(item.updatedAt)) {
                acc[key] = item; // Keep the most recent item
                errors.push(`Duplicate routine for ${key}.`);
                //console.log(`Duplicate routine for ${key}.`, { item });
                //Rollbar.error(`Duplicate routine for ${key}.`, { item });
              }
            } else {
              acc[key] = item; // Keep the most recent item
            }
          }
          return acc;
        }, {})
      );

      const baseScore = sessionScore(routines, teamScoringType);
      const { neutral, conduct } = sessionPenalties(currentLineup);
      const teamScore = baseScore - neutral;

      if (!individuals) {
        rankings['team'] = [...rankings['team'], teamScore];
      }

      const teamAltName = displayName(
        currentLineup.team.name,
        currentLineup.team.altNames
      );
      const teamDisplayName = forceTitle ? title : teamAltName;

      allScores.push(
        ...routines
          .map((r) => {
            const rotation = r.rotation;
            const lineupSlot = lineupData?.[rotation]?.[r.order];

            if (!lineupSlot) {
              return {};
            }

            const athleteId = lineupData[rotation][r.order]?.athleteId;
            const name = lineupData[rotation][r.order]?.name;
            const matchLineup = athleteId === r.athleteId;
            const rScore = routineScore(r);
            if (!rScore?.exhibition) {
              rankings[r.apparatus] = rankings[r.apparatus]
                ? [...rankings[r.apparatus], r.score]
                : [r.score];
              rankings['AA'][r.athleteId] = rankings['AA'][r.athleteId]
                ? (rankings['AA'][r.athleteId] += r.score)
                : r.score;
            }

            // Apply to lineup object
            lineupData[rotation][r.order]['score'] = r.score;

            return {
              apparatus: r.apparatus,
              score: convertMillipointsToDisplay(r.score, scorePrecision),
              order: r.order,
              team: teamDisplayName,
              teamFullName: currentLineup?.team?.name,
              rotation: r.rotation,
              name: matchLineup ? name : '',
              updatedAt: r.updatedAt,
              startedAt: r.startedAt,
              completedAt: r.completedAt,
              athleteId: r.athleteId,
              //gender: currentLineup.team.gender,
              teamLogo: JSON.parse(currentLineup.team?.logos)?.metaData
                ?.filename,
              status: r.status,
              d: convertMillipointsToDisplay(rScore.scoreD, dScorePrecision),
              e: convertMillipointsToDisplay(
                rScore.scoreEAverage,
                avgPrecision
              ),
              sb: convertMillipointsToDisplay(rScore.scoreSB, sbPrecision),
              scoresJ: r.scoresJ,
              scoresE: r.scoresE,
              scoresD: r.scoresD,
              scoresSV: r.scoresSV,
              scoreEAverage: r.scoreEAverage,
              teamScore: teamScore,
              exhibition: rScore.exhibition,
            };
          })
          .filter((r) => r.name && r.athleteId)
      );

      const runningScoreObj = {};
      const runningAAObj = {};

      const events =
        (!!lineupData &&
          Object.keys(apparatusSet).map((e, i) => {
            const k = i + 1; // to adjust for Rotations start from 1
            const apparatus = e;
            const rotation = rotationOrder.byApparatus[e];
            const {
              routines: eventRoutines,
              score: eventScoreMilli,
              teamRoutineIds: teamScoreIds,
              average: eventAverageMilli,
            } = rotationScore(routines, apparatus, teamScoringType);
            const eventScore = !!eventScoreMilli
              ? convertMillipointsToDisplay(eventScoreMilli, scorePrecision)
              : '';
            const eventAverage = !!eventAverageMilli
              ? convertMillipointsToDisplay(eventAverageMilli, scorePrecision)
              : '';
            runningScoreObj[rotation] = eventScoreMilli;

            const lineupDataScores =
              lineupData?.[k]?.map((l) => {
                const routine = eventRoutines.find(
                  (r) => r.athleteId === l.athleteId
                );
                const rScore = routineScore(routine);
                const {
                  scoreND,
                  scoreSB,
                  scoresJ,
                  scoresD,
                  scoresE,
                  scoresSV,
                  scoreEAverage,
                  score,
                  exhibition,
                } = rScore;

                const finalScore =
                  !!score || score === 0
                    ? convertMillipointsToDisplay(score, scorePrecision)
                    : null;

                runningAAObj[l.name] = {
                  ...runningAAObj[l.name],
                  [apparatus]: { score: finalScore, order: l.order },
                };

                return {
                  name: l.name,
                  order: l.order,
                  isTeamRoutine: teamScoreIds.includes(routine?.id),
                  exhibition,
                  athleteId: l.athleteId,
                  finalScore,
                  scores: {
                    scoreEAverage:
                      !!scoreEAverage && scoreEAverage !== 0
                        ? convertMillipointsToDisplay(
                            scoreEAverage,
                            avgPrecision
                          )
                        : '',
                    ND:
                      !!scoreND && scoreND !== 0
                        ? convertMillipointsToDisplay(scoreND, nPrecision)
                        : '',
                    SB:
                      !!scoreSB && scoreSB !== 0
                        ? convertMillipointsToDisplay(scoreSB, sbPrecision)
                        : '',
                    ...scoresSV?.reduce(
                      (o, key, i) =>
                        Object.assign(o, {
                          [`SV${i + 1}`]: convertMillipointsToDisplay(
                            key,
                            svPrecision
                          ),
                        }),
                      {}
                    ),
                    ...scoresJ?.reduce(
                      (o, key, i) =>
                        Object.assign(o, {
                          [`J${i + 1}`]: convertMillipointsToDisplay(
                            key,
                            jPrecision
                          ),
                        }),
                      {}
                    ),
                    ...scoresD?.reduce(
                      (o, key, i) =>
                        Object.assign(o, {
                          [`D${
                            scoresD.length > 1 ? i + 1 : ''
                          }`]: convertMillipointsToDisplay(key, dPrecision),
                        }),
                      {}
                    ),
                    ...scoresE?.reduce(
                      (o, key, i) =>
                        Object.assign(o, {
                          [`E${i + 1}`]: convertMillipointsToDisplay(
                            key,
                            ePrecision
                          ),
                        }),
                      {}
                    ),
                  },
                };
              }) || [];

            return {
              apparatus,
              rotation,
              neutral: 0,
              eventScore,
              eventAverage,
              lineup: lineupDataScores,
            };
          })) ||
        [];

      // Reconcile running score based on rotation order
      let runningScore = 0;

      for (let k = 1; k < events.length + 1; k++) {
        const sortedByRotation = events
          .map((e) => e.rotation)
          .sort((a, b) => a - b);
        const rotIndex = events.findIndex(
          (e) => e.rotation === sortedByRotation[k - 1]
        );

        if (rotIndex === -1) {
          break;
        }

        const eventScore =
          convertDisplayToMillipoints(
            events[rotIndex]?.['eventScore'],
            scorePrecision
          ) || 0;

        events[rotIndex]['runningScore'] =
          !!eventScore &&
          convertMillipointsToDisplay(
            runningScore + eventScore,
            scorePrecision
          );
        runningScore += eventScore;
      }

      // Reconcile AA competitors & scores
      const aa = [];
      const { maxOrder, maxApparatus } = aaCriteria(
        teamScoringType,
        genderType
      );

      !!runningAAObj &&
        Object.keys(runningAAObj).forEach((a) => {
          let count = 0;
          let aaScore = 0;

          Object.keys(runningAAObj[a]).forEach((app) => {
            if (runningAAObj[a][app].order <= maxOrder) {
              count++;
              const appScore = runningAAObj[a][app]?.score ?? 0;
              aaScore += convertDisplayToMillipoints(appScore, scorePrecision);
            }
          });

          if (count === maxApparatus) {
            aa.push({
              name: a,
              team: teamDisplayName,
              teamFullName: currentLineup?.team?.name || '',
              triCode: currentLineup?.team?.triCode || '',
              scores: {
                ...runningAAObj[a],
                AA: {
                  score: aaScore
                    ? convertMillipointsToDisplay(aaScore, scorePrecision)
                    : '',
                },
              },
            });
          }
        });

      teams.push({
        name: currentLineup?.team?.name ?? '',
        altNames: currentLineup?.team?.altNames ?? '',
        altName: teamAltName,
        displayName: teamDisplayName,
        forceTitle: currentLineup?.forceTitle,
        title: currentLineup?.title || '',
        logos: currentLineup?.team?.logos ?? '',
        colors: currentLineup?.team?.colors ?? '',
        rtnId: currentLineup?.team?.rtnId ?? '',
        triCode: currentLineup?.team?.triCode ?? '',
        id: currentLineup?.team?.id,
        // note isHomeTeam not available like in API, due to session?.hostId needed
        individuals: currentLineup?.individuals,
        neutral,
        videoReview: 0,
        conductCard: conduct,
        rank: 0,
        isTie: false,
        rankOut: 0,
        baseScore: baseScore,
        finalScore: !!teamScore
          ? convertMillipointsToDisplay(teamScore, scorePrecision)
          : '',
        events: events,
        order: currentLineup?.order,
        lineupId: currentLineup.id,
        aa,
      });
    }

    // Apply rankings
    const aaRankings = rankings['AA'];
    rankings['AA'] = Object.values(rankings['AA']);
    Object.keys(rankings).forEach((k) => {
      rankings[k] = rankings[k].slice().sort((a, b) => b - a);
    });

    // Prep AA Score Rankings
    const individuals = { AA: [] };
    Object.keys(apparatusSet).forEach((key) => {
      individuals[key] = [];
    });

    // Populate Team & AA Score Rankings
    teams.forEach((t) => {
      const teamScore = convertDisplayToMillipoints(
        t.finalScore,
        scorePrecision
      );
      t.rank =
        !!teamScore && !t?.individuals
          ? rankings['team'].indexOf(teamScore) + 1
          : '';
      t.isTie =
        !t?.individuals &&
        t.rank < rankings['team'].length &&
        teamScore === rankings['team'][t.rank];
      const teamRankDelta = rankings['team'][0] - teamScore;
      t.rankOut =
        !!teamScore && !t?.individuals
          ? teamRankDelta > 0
            ? convertMillipointsToDisplay(
                rankings['team'][0] - teamScore,
                scorePrecision
              )
            : '--'
          : '';

      individuals['AA'] = [...individuals['AA'], ...t.aa];
    });

    // AA Rankings
    individuals['AA'].forEach((i) => {
      const aaScore = convertDisplayToMillipoints(
        i.scores['AA'].score,
        scorePrecision
      );
      const rank = !!aaScore ? rankings['AA'].indexOf(aaScore) + 1 : '';
      i.scores['AA']['rank'] = rank;
      i.scores['AA']['isTie'] =
        rank < rankings['AA'].length && aaScore === rankings['AA'][rank];
      const aaRankDelta = rankings['AA'][0] - aaScore;
      i.scores['AA']['rankOut'] = !!aaScore
        ? aaRankDelta > 0
          ? convertMillipointsToDisplay(aaRankDelta, scorePrecision)
          : '--'
        : '';
    });

    // Individual Score Rankings
    allScores.forEach((s) => {
      if (s.exhibition) {
        return;
      }

      const score = convertDisplayToMillipoints(s.score, scorePrecision);
      s.rank = rankings?.[s.apparatus]?.indexOf(score) + 1;
      s.isTie =
        s.rank < rankings[s.apparatus].length &&
        score === rankings[s.apparatus][s.rank];
      const rankDelta = rankings[s.apparatus][0] - score;
      s.rankOut =
        rankDelta === 0
          ? '--'
          : convertMillipointsToDisplay(rankDelta, scorePrecision);
      s.teamRanking = rankings['team'].indexOf(s.teamScore) + 1;
      s.teamIsTie =
        s.teamRanking < rankings['team'].length &&
        s.teamScore === rankings['team'][s.teamRanking];
      s.aaRanking = rankings['AA'].indexOf(aaRankings[s.athleteId]) + 1;
      s.aaIsTie =
        s.aaRanking < rankings['AA'].length &&
        aaRankings[s.athleteId] === rankings['AA'][s.aaRanking];

      individuals[s.apparatus].push(s);
    });

    // Sort by CompletedAt
    const scores = allScores.sort((a, b) => {
      return (
        new Date(a.completedAt).getTime() - new Date(b.completedAt).getTime()
      );
    });

    // Add Rotations summary
    const rotationsData = rotations.map((r, rNum) => {
      const result = teams.map((t) => {
        return t.events.find((e) => {
          return e.rotation === rNum + 1;
        });
      });

      const apparatusList = GYMapparatusAbbv(genderType);
      return result.sort((a, b) => {
        const aIndex = apparatusList.indexOf(a.apparatus);
        const bIndex = apparatusList.indexOf(b.apparatus);

        if (aIndex === -1) return 1;
        if (bIndex === -1) return 1;

        return aIndex - bIndex;
      });
    });

    return {
      teams,
      rankings,
      scores,
      individuals,
      rotations: rotationsData,
      errors,
    };
  }

  return teamData;
}

export function useTeamLineups() {
  function lineup(round) {}

  return lineup;
}

export function legacyTeamRotations(apparatusList = [], isAlternating = false) {
  let result = {};
  let resultByOrder = {};
  let resultByApparatus = {};

  for (let i = 0; i < apparatusList.length; i++) {
    let order = isAlternating ? dualTeamBRotation(i + 1) : i + 1;
    resultByOrder[order] = apparatusList[i];
    resultByApparatus[apparatusList[i]] = order;
  }

  result['byOrder'] = resultByOrder;
  result['byApparatus'] = resultByApparatus;
  result['configured'] = true;
  return result;
}

export function useTeamRotations() {
  function rotationList(rotations, teamId, lineupId = null) {
    let result = {};
    let resultByOrder = {};
    let resultByApparatus = {};

    for (let i = 0; i < rotations.length; i++) {
      const stages = rotations[i]?.stages?.items;
      for (let j = 0; j < stages.length; j++) {
        const rosters = stages[j]?.squad?.rosters?.items || [];
        for (let k = 0; k < rosters.length; k++) {
          if (rosters[k]?.roster?.teamId === teamId) {
            if (!lineupId || lineupId === rosters[k]?.lineupId) {
              resultByOrder[rotations[i].order + 1] = stages[j].apparatus;
              resultByApparatus[stages[j].apparatus] = rotations[i].order + 1;
            }
          }
        }
      }
    }

    result['byOrder'] = resultByOrder;
    result['byApparatus'] = resultByApparatus;
    result['configured'] =
      Object.keys(resultByOrder).length +
        Object.keys(resultByApparatus).length >
      0;
    return result;
  }

  return rotationList;
}

export const scoresheetFormat = (panelType) => {
  let config = null;
  const {
    NCAAM,
    NCAAM4,
    NCAAW,
    NCAAW2,
    NCAAW2SV,
    NCAAW4,
    NCAAW6,
  } = JudgePanelType;

  switch (panelType) {
    case NCAAW:
    case NCAAW2:
    case NCAAW2SV:
    case NCAAW4:
      config = {
        title: `NCAA Women's Gymnastics Score Sheet`,
        isChampionships: false,
        isMakeMultiDuals: false,
      };
      break;
    case NCAAW6:
      config = {
        title: `NCAA Women's Championships Score Sheet`,
        isChampionships: false,
        isMakeMultiDuals: false,
      };
      break;
    case NCAAM:
    case NCAAM4:
      config = {
        title: `NCAA Mens's Gymnastics Score Sheet`,
        isChampionships: false,
        isMakeMultiDuals: false,
      };
      break;
    default:
      config = {
        title: `NCAA Gymnastics Score Sheet`,
        isChampionships: false,
        isMakeMultiDuals: false,
      };
  }

  return config;
};

export function useTeamsByApparatusData() {
  const teamsData = useTeamsData();

  function sessionData(session) {
    const teamScoring = session?.teamScoring;
    const gender = session?.gender;
    const alternating = session?.alternating;
    const lineups = session?.lineups?.items?.filter(
      (l) => !l?._deleted && !l?.individuals
    );
    // const lineups = session?.lineups?.items?.filter((l) => !l?._deleted);
    const rotations = session?.rotations?.items.filter((r) => !r?._deleted);
    const fullSessionData = teamsData(
      lineups,
      teamScoring,
      gender,
      alternating,
      rotations
    );

    return fullSessionData;
  }

  return sessionData;
}

export function useScoreSheet() {
  const teamsData = useTeamsData();

  function scoresheet(session, { altTeamScoring }) {
    const teamScoring = altTeamScoring ?? session?.teamScoring;
    const gender = session?.gender;
    const alternating = session?.alternating;
    const lineups = session?.lineups?.items;
    const date = session?.startAt;
    const location = session?.location;
    const attendance = session?.attendance;
    const judges = session?.judges;
    const aa = null;
    const teamAScores = null;
    const teamBScores = null;
    const format = scoresheetFormat(session?.judgePanel);
    const apparatus = session?.apparatus;
    const rotations = session?.rotations?.items;
    const fullSessionData = teamsData(
      lineups,
      teamScoring,
      gender,
      alternating,
      rotations
    );

    //console.log(fullSessionData);

    return {
      sessionData: fullSessionData,
      date,
      location,
      attendance,
      judges,
      aa,
      teamAScores,
      teamBScores,
      format,
      apparatus,
    };
  }

  return scoresheet;
}

export function useCombineScoreSheet() {
  const teamsData = useTeamsData();

  function scoresheet(session, { altTeamScoring, combineSession = [] }) {
    const teamScoring = altTeamScoring ?? session?.teamScoring;
    const gender = session?.gender;
    const alternating = session?.alternating;
    const date = session?.startAt;
    const location = session?.location;
    const attendance = session?.attendance;
    const judges = session?.judges;
    const aa = null;
    const teamAScores = null;
    const teamBScores = null;
    const format = scoresheetFormat(session?.judgePanel);
    const apparatus = session?.apparatus;
    const sessionLineups = session?.lineups?.items || [];
    const combineSessionLineups =
      combineSession?.lineups?.items?.map((l) => {
        return {
          ...l,
          order: sessionLineups.length + l.order,
        };
      }) || [];
    const sessionRotations = session?.rotations?.items || [];

    const lineups = [...sessionLineups, ...combineSessionLineups];
    const rotations = sessionRotations;

    const fullSessionData = teamsData(
      lineups,
      teamScoring,
      gender,
      alternating,
      rotations
    );

    return {
      sessionData: fullSessionData,
      date,
      location,
      attendance,
      judges,
      aa,
      teamAScores,
      teamBScores,
      format,
      apparatus,
    };
  }

  return scoresheet;
}
